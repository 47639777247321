import { Container, Grid } from '@mui/material';
import { Skeleton } from 'primereact/skeleton';


const ProductListShimmer = () => {
    return (
        <>
            <Container
                maxWidth={"lg"}
                className="wrap-productlist"
            >
                <div className="flex justify-between gap-4 ">
                    <div id="filter-sidebar" className="w-64 sidebar-filter-wrapper">
                        <Skeleton className='rounded-xl' height="30rem"></Skeleton>
                    </div>
                    <div className="product-list-rightwrap w-full">
                        <div className="flex flex-col w-full productwrap-inner">
                            <div id="sort-bar" className="flex justify-between mb-4 sortbar-wrapper">
                                <Skeleton className='rounded-xl' width="10rem" height="3rem"></Skeleton>
                                <Skeleton className='rounded-xl' width="10rem" height="3rem"></Skeleton>
                            </div>
                            <div className="product-listing w-full">
                                <div className="flex flex-col w-full">
                                    <Grid container columns={20} className="flex justify-start items-stretch gap-4 grid-items">
                                        {Array.from({ length: 9 }).map((_, index) => (
                                            <Skeleton className='rounded-xl' width="18rem" height="20rem"></Skeleton>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default ProductListShimmer;
