export const SERVER_ENDPONTS = {
  SERVER_API: "http://localhost:5002",
  AUTH: {
    SIGN_UP: "/user/auth/signup",
    LOGIN: "/user/auth/login",
    VERIFY_EMAIL: "/user/auth/verify/email",
    RESEND_OTP: "/user/auth/resend/otp",
    CHANGE_PASSWORD: "/user/auth/change-password",
    FORGOT_PASSWORD: "/user/auth/forgot-password",
    GUEST: "/user/auth/guest",
    PINCODE_CHECK: "user/pincode/check",
    UPDATE_STEP: "user/profile/update",
    ADDRESS_LIST: "user/profile/address/list",
    ADDRESS_ADD: "user/profile/address/add",
    ADDRESS_DELETE: "user/profile/address/delete", 
    ACCOUNT_DELETE: "user/profile/delete-account", 
    UPDATE_PROFILE:"user/profile/update",
    DETAILS: "user/profile",
  },
  CATALOG: {
    CATALOG_LIST: "/user/catalog/list",
    ADD_REMOVE_FROM_WISHLIST: "/user/catalog/wishlist",
    ALL_WISHLIST: "/user/catalog/wishlist",
    PRODUCT_DETAILS: "/user/catalog/details",
  },
  CART: {
    ADD_TO_CART: "/user/cart/add",
    REMOVE_FROM_CART: "/user/cart/remove",
    EMPTY_CART: "/user/cart/delete",
    UPDATE_CART: "/user/cart/update",
    GET_ALL_CART: "/user/cart",
    // FIND_IN_CART: "/user/cart/check",
    COUNT: "/user/cart/count",
  },
  PAYMENT: {
    CHECKOUT: "/user/payment/checkout",
    VERIFY: "/user/payment/verify",
    ORDER_LIST: "user/profile/order/list",
    ORDER_BY_ID: "user/profile/order/detail",
    TRANSACTION_LIST: "user/payment/my/list",
    TRANSACTION_BY_ID: "user/payment/my/list",
  },
  CATEGORY: "/user/category",
  BRAND: "/user/brand/list",
  CONFIG: "/user/configuration",
};
