import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStore } from "../../configStore";

const ContactUs = () => {
  const dispatch = useDispatch();
  const contactInfo = useStore((state: any) => state.configurations.contactInfo);

  return (
    <div className="contact-wrapper staticpage">
      <div className="lg:mb-8 md:mb-4 md:text-2xl color-lightblack font-bold innertitle">
        <strong>Contact Us</strong>
      </div>

      {contactInfo ? (
        <>
          <p className="pt-4">
            <strong>{contactInfo.title}</strong> <br></br>
            Pushkar Foods (PUSHKAR INTERNATIONAL MERCHANDISERS) welcomes your inquiries, feedback, and suggestions. You can reach us through the following contact details:
          </p>

          <div className="pt-3">
            <p>
              <strong>Email:</strong>{" "}
              <a href={`mailto:${contactInfo.email}`} className="text-blue-500">
                {contactInfo.email}
              </a>
            </p>
            <p>
              <strong>Phone:</strong>{" "}
              <a href={`tel:+91-${contactInfo.phoneNumber}`} className="text-blue-500">
                +91-{contactInfo.phoneNumber}
              </a>
            </p>
            <p>
              <strong>Address:</strong> {contactInfo.address}
            </p>
          </div>

          <div className="pt-6">
            <p>
              For further assistance, please do not hesitate to contact our support team. We are here to help and ensure you have a seamless experience with Pushkar Foods (PUSHKAR INTERNATIONAL MERCHANDISERS).
            </p>
          </div>
        </>
      ) : (
        <p className="pt-4">Loading contact information...</p>
      )}
    </div>
  );
};

export default ContactUs;
