import {
  Container
} from "@mui/material";
import  { useEffect, useRef, useState } from "react";
import {
  deleteUserAddress,
  getUserAddressList,
  updateUserDetails,
} from "../../../api/lib/user";
import LocationPopup from "../../../components/pop-up-components/locationPopup";
import { useAuth } from "../../../context/AuthContext";
import { Icon } from "@iconify/react";
import { Toast } from "primereact/toast";

const EditProfile = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const [address, setAddress] = useState([]);
  const [fullName, setFullName] = useState(currentUser?.fullName || "");
  const [editMode, setEditMode] = useState(false);
  const fullNameRef = useRef<HTMLInputElement>();
  const toast = useRef(null);
  const [isLocationPopupVisible, setIsLocationPopupVisible] = useState(false);

  useEffect(() => {
    getAddressList();
  }, []);

  const handleHide = () => {
    getAddressList();
    if (toast.current) {
      toast.current.show({
        severity: "success",
        summary: "Address Added Successfully",
        detail: "Successful",
        life: 3000,
      });
    }
  };

  const showAddressPopup = () => {
    // setVisible(true);
    setIsLocationPopupVisible(true)
  };

  const handleSave = async () => {
    try {
      setEditMode(false);
      const phone = currentUser?.phone;
      await updateUserDetails({ fullName: fullName, phone: phone });
      const updatedUser = { ...currentUser, fullName };
      localStorage.setItem("authUser", JSON.stringify(updatedUser));
      setCurrentUser(updatedUser);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Profile Updated",
          detail: "Successful",
          life: 3000,
        });
      }
    } catch (error) {
      // console.error("Error Saving Profile", error);
    }
  };

  const getAddressList = async () => {
    try {
      if (currentUser) {
        const response = await getUserAddressList();
        if (response.data?.data[0]) {
          setAddress(response.data?.data);
        }
      } else {
        // setAddress("Location");
      }
    } catch (error) {
      // console.error("Error fetching address list", error);
    }
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      await deleteUserAddress(addressId);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Address Deleted",
          detail: "Successful",
          life: 3000,
        });
      }
      setAddress((prevAddresses) =>
        prevAddresses.filter((addr) => addr._id !== addressId)
      );
      // getAddressList();
    } catch (error) {
      // console.error("Error Deleting Address", error);
    }
  };

  const handleEditProfile = () => {
    setEditMode(true);
    setTimeout(() => {
      if (fullNameRef.current) {
        fullNameRef.current.focus();
      }
    }, 0);
  };

  return (
    <>
      <Container maxWidth="lg" className="wrapEditprofile">
        <Toast  position="top-center"  className="toast" ref={toast}></Toast>
        <div className="titleWrapper flex justify-between md:mt-8">
          <p className="md:text-3xl text-lg color-lightblack font-bold">
            Edit Profile
          </p>
          <button
            data-popover-target="menu"
            className="btn-address md:rounded-xl rounded-[5px] py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] hidden md:flex items-center justify-center min-w-[148px] text-white transition-all hover:bg-transparent hover:border-[#CD1318] hover:text-[#CD1318]"
            type="button"
            onClick={showAddressPopup}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="23"
              fill="none"
              viewBox="0 0 22 23"
              className="mr-2"
            >
              <path
                fill="#fff"
                d="M11 .5a11 11 0 1 0 11 11 11.013 11.013 0 0 0-11-11m5 12h-4v4a1 1 0 0 1-2 0v-4H6a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2"
              ></path>
            </svg>
            Add New Address
          </button>
        </div>
        <div className="profile-wrapper mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-left">
            <div className="profile-info-card mb-6 md:max-w-[80%] bg-white border border-[#EFEFF1] rounded-3xl shadow-[0px_0px_20px_6px_#00000017;] py-4 px-6">
              <button
                onClick={handleEditProfile}
                className="text-[#CD1318] editbtn font-semibold flex items-center justify-self-end"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="none"
                  viewBox="0 0 15 15"
                  className="mr-1"
                >
                  <path
                    fill="#CD1318"
                    d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                  ></path>
                  <path
                    fill="#CD1318"
                    d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                  ></path>
                </svg>
                Edit
              </button>

              <div className="field-wrapper">
                <div className="field-group">
                  <label>Full Name</label>
                  {/* <div id="name" className="name field-group-value">
                    {currentUser?.fullName || ""}
                  </div> */}
                  <input
                    ref={fullNameRef}
                    className={`py-1 px-1 rounded-md ${
                      editMode ? "input-active focus:border-blue-light outline-none" : "not-active outline-none"
                    }`}
                    type="text"
                    value={fullName}
                    placeholder="First Name"
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    readOnly={!editMode}
                  />
                </div>
                <div className="field-group">
                  <label>Mobile Number</label>
                  <div id="mnumber" className="mobile-number field-group-value">
                    {currentUser?.phone || ""}
                  </div>
                </div>
                <div className="field-group">
                  <label>Email ID</label>
                  <div id="email" className="email field-group-value">
                    {currentUser?.email || ""}
                  </div>
                </div>
                {/* <div className="field-group">
                  <label>Legal Entity Name</label>
                  <div id="Ename" className="entityname field-group-value">
                    Guest Account
                  </div>
                </div> */}
                {/* <div className="field-group">
                  <label>PAN Card Number</label>
                  <div id="pan" className="panumber text-[#CD1318]">
                    Unverified
                  </div>
                </div> */}
                {editMode ? 
                <div id='saveButton' className="text-[#CD1318] editbtn font-semibold flex items-center justify-self-end justify-center">
                  <button onClick={handleSave}>Save</button>
                </div>
                : ''}
              </div>
            </div>
            {/* <div className="password-card md:max-w-[80%] bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6">
              <div className="field-group">
                <label>Current Password</label>
                <input
                  type="password"
                  className="password border border-[#EFEFF1] text-[#9596A4] rounded-xl p-3 w-full 
                            disabled:border-white disabled:bg-white disabled:text-4xl"
                  id="password"
                  value="Password"
                  disabled
                />
              </div>
              <button className="btn-update bg-[#3C881F] md:h-[63px] md:min-w-[197px] min-w-[120px] p-2 text-white border border-transparent md:text-base text-[10px] text-center font-semibold rounded-[32px] hover:bg-white hover:border-[#3C881F] hover:text-[#3C881F]">
                Change Password
              </button>
            </div> */}
          </div>
          <div className="col-right">
            {address?.map((address) => (
              <div key={address._id} className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4">
                <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-3 mb-4">
                  <div className="title font-semibold color-lightblack md:text-base text-sm">
                    {address.isDefault ? 'Default Address' : 'Other Address'}
                  </div>
                  <div className="flex gap-2 items-center">
                    {/* <button  className="editbtn text-[#CD1318] font-semibold flex items-center justify-self-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="none"
                        viewBox="0 0 15 15"
                        className="mr-1"
                      >
                        <path
                          fill="#CD1318"
                          d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                        ></path>
                        <path
                          fill="#CD1318"
                          d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                        ></path>
                      </svg>
                      Edit
                    </button> */}
                    {address.isDefault ? '' : 
                    <button onClick={()=>handleDeleteAddress(address._id)} className="text-[#CD1318] font-semibold flex items-center justify-self-end "><Icon icon="mingcute:delete-line" width="16" height="16" /> Delete</button>
                    }
                  </div>
                </div>
                <div className="address-detail">
                  <p className="text-[#6E6F72]">
                    {address?.fullAddress}
                    <br />
                    Pin - {address?.pincode}
                    <br />
                  </p>
                  <p className="color-lightblack font-semibold">
                    {address?.fullName} <br /> Mobile: {address?.mobileNumber}
                  </p>
                </div>
              </div>
            ))}

            {/* <div className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4">
              <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-3 mb-4">
                <div className="title font-semibold color-lightblack md:text-base text-sm">
                  Other Address
                </div>
                <button className="editbtn text-[#CD1318] font-semibold flex items-center justify-self-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    viewBox="0 0 15 15"
                    className="mr-1"
                  >
                    <path
                      fill="#CD1318"
                      d="M11.196 7.928a.59.59 0 0 0-.589.59v4.714a.59.59 0 0 1-.59.589h-8.25a.59.59 0 0 1-.588-.59v-8.25a.59.59 0 0 1 .589-.589h4.714a.59.59 0 1 0 0-1.178H1.768A1.77 1.77 0 0 0 0 4.982v8.25A1.77 1.77 0 0 0 1.768 15h8.25a1.77 1.77 0 0 0 1.768-1.768V8.517a.59.59 0 0 0-.59-.589"
                    ></path>
                    <path
                      fill="#CD1318"
                      d="M5.877 6.967a.3.3 0 0 0-.083.155l-.431 2.156a.306.306 0 0 0 .359.359l2.155-.431a.3.3 0 0 0 .156-.084l4.824-4.824-2.155-2.155zM14.584.416a1.423 1.423 0 0 0-2.011 0l-.787.787 2.01 2.011.788-.787A1.4 1.4 0 0 0 15 1.422c0-.38-.148-.738-.416-1.006"
                    ></path>
                  </svg>
                  Edit
                </button>
              </div>
              <div className="address-detail">
                <p className="text-[#6E6F72]">
                  House no. 465 Arjun nagar gali no. 2, Behind Tirath Ram
                  Hospital Arjun Nagar
                  <br />
                  Gurugram - 122001
                  <br />
                  Haryana
                </p>
                <p className="color-lightblack font-semibold">
                  Prateeksha <br /> Mobile: 7042373798
                </p>
              </div>
            </div> */}

            <button
              data-popover-target="menu"
              className="btn-address md:rounded-xl rounded-[5px] py-2 mb-1 px-4 bg-[#CD1318] border border-transparent md:text-lg text-[10px] md:hidden flex items-center justify-center min-w-[148px] text-white transition-all hover:bg-transparent hover:border-[#CD1318] hover:text-[#CD1318]"
              type="button"
              onClick={showAddressPopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="23"
                fill="none"
                viewBox="0 0 22 23"
                className="mr-2"
              >
                <path
                  fill="#fff"
                  d="M11 .5a11 11 0 1 0 11 11 11.013 11.013 0 0 0-11-11m5 12h-4v4a1 1 0 0 1-2 0v-4H6a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2"
                ></path>
              </svg>
              Add New Address
            </button>
          </div>
        </div>
      </Container>
      {isLocationPopupVisible && (
        <LocationPopup
          setVisibleLogin={setIsLocationPopupVisible}
          isSignUp={false}
          initialManualVisible={true}
          handleHide={handleHide}
        />
      )}
    </>
  );
};

export default EditProfile;
