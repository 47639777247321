import React from 'react'
import { Container } from '@mui/material'

const WholeSaleSolution = () => {
  return (
    <Container maxWidth={"lg"} className='wholesalesection pt-8 pb-6'>
        <div className='lg:mb-10 flex mb-4 wholesale-content'>
        
            <div className='md:text-4xl text-xl contenttitle font-semibold w-full lg:w-4/6 pr-2'>
                Pushkar Foods (PUSHKAR INTERNATIONAL MERCHANDISERS) :
                <span className='font-medium'> Your Trusted Wholesale Partner</span>
            </div>
            <div className='ContentWrap color-lightblack w-full'>
                <p className='mb-4'>
                At Pushkar Foods (PUSHKAR INTERNATIONAL MERCHANDISERS), we pride ourselves on being more than just a supplier; we are your dedicated partner in success. 
                We specialize in serving across various sectors, including retailers, hotels, restaurants, and caterers (HoReCa). With 
                a commitment to quality, reliability, and exceptional service, we provide your business with high-quality products 
                from leading multinational brands, ensuring all your requirements are met.
                </p>
                <p className='mb-4'>
                As a B2B Cash and Carry wholesaler with a strong presence in Delhi-NCR, we offer a diverse range of products 
                available for easy ordering through our user-friendly website. Our dedicated sales team prioritizes your daily 
                requirements, delivering exceptional service with agility and efficiency.
                </p>
                <p className='mb-4'>
                At Pushkar Foods (PUSHKAR INTERNATIONAL MERCHANDISERS), we aspire to be your trusted partner for all your wholesale needs!
                </p>
            </div>
    
        </div>
    </Container>
  )
}

export default WholeSaleSolution
